html,
body {
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar {
  background-color: #2b2b2b;
}

*::-webkit-scrollbar-corner {
  background-color: #2b2b2b;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #6b6b6b;
  min-height: 24px;
  border: 3px solid #2b2b2b;
}
